<template>
  <div class="container-fluid">
    <header class="header">
      <img :src="logo" alt="logo" class="logo" />
    </header>

    <div class="row">
      <div class="offset-2 col-6">
        <div class="alerts"></div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-lg-6 col-xl-4">
        <form @submit.prevent="verify">
          <!-- {{csrf_field()}} -->

          <div class="form-group row justify-content-center">
            <div class="col-12">
              <label for="pin" class="col-form-label text-light-20"
                >Pin<sup class="text-danger">*</sup></label
              >
              <input
                type="text"
                name="pin"
                id="pin"
                class="form-control"
                :class="{ 'is-invalid': !pinIsValid }"
                placeholder="Pin"
                v-model.trim="enteredPin"
              />
              <div class="invalid-feedback">{{ pinFeedback }}</div>
            </div>
          </div>

          <div class="form-group row justify-content-center">
            <div class="col-12">
              <label for="reference_no" class="col-form-label text-light-20"
                >Reference No.<sup class="text-danger">*</sup></label
              >
              <input
                type="text"
                name="reference_no"
                id="reference-no"
                class="form-control"
                :class="{ 'is-invalid': !referenceNoIsValid }"
                placeholder="Reference No."
                v-model.trim="enteredReferenceNo"
              />
              <div class="invalid-feedback">{{ referenceNoFeedback }}</div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-12">
              <button type="submit" id="" class="btn btn-cyan col-12 mt-30">
                Verify
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  emits: ['result-received'],
  data() {
    return {
      logo: '/img/FRC-logo-light.png',
      enteredPin: '',
      enteredReferenceNo: '',
      pinIsValid: true,
      referenceNoIsValid: true,
      pinFeedback: '',
      referenceNoFeedback: '',
      anyAlert: false,
    };
  },
  methods: {
    verify() {
      this.pinIsValid = true;
      this.referenceNoIsValid = true;
      this.pinFeedback = '';
      this.referenceNoFeedback = '';
      this.anyAlert = false;
      // if (this.enteredPin === '' || this.enteredReferenceNo === ''){
      //   return;
      // }

      // fetch(
      //   'https://admin.frc.gov.so/api/v1/physical-cash-transportation/verify',
      //   {
      //     method: 'POST',
      //     headers: {
      //       // 'X-CSRF-TOKEN': '{{ csrf_token() }}',
      //       // Accept: 'application/json',
      //       'Content-Type': 'application/json',
      //     },
      //     // mode: 'no-cors',
      //     body: JSON.stringify({
      //        pin: this.enteredPin,
      //        reference_no: this.enteredReferenceNo,
      //     });
      //   }
      // )
      //   .then((response) => {
      //     console.log(response);
      //     return response.json();
      //   })
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      axios
        .post(
          'https://admin.frc.gov.so/api/v1/physical-cash-transportation/verify',
          {
            pin: this.enteredPin,
            reference_no: this.enteredReferenceNo,
          }
          // {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // }
        )
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          if (response.status === 200) {
            if (response.data.status === true) {
              let data = {
                inflow: response.data.inflow,
                outflow: response.data.outflow,
                organization: response.data.organization,
                pct: response.data.pct,
                document: response.data.passport_doc
                  ? response.data.passport_doc
                  : null,
              };
              this.$emit('result-received', data);
            } else {
              if (!response.data.organization) {
                this.pinIsValid = false;
                this.pinFeedback = response.data.message;
              } else {
                this.referenceNoIsValid = false;
                this.referenceNoFeedback = response.data.message;
              }
            }
          }
        })
        .catch((error) => {
          // console.log(error.response);
          // console.log(error.response.data);
          if (error.response.status === 422) {
            if (error.response.data.errors.pin) {
              this.pinIsValid = false;
              this.pinFeedback = error.response.data.errors.pin[0];
            }
            if (error.response.data.errors.reference_no) {
              this.referenceNoIsValid = false;
              this.referenceNoFeedback =
                error.response.data.errors.reference_no[0];
            }
          } else {
            // console.log(error.response);
            // console.log(error.response.data);
            this.$toast.show(`Something went wrong!`, {
              type: 'error',
              position: 'top',
              duration: 4000,
              dismissible: true,
              maxToasts: 1,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.logo,
label,
input,
button {
  position: relative;
  z-index: 120;
}

.header {
  margin: 0 0 2rem 0;
  text-align: center;
}

.logo {
  width: 30rem;
}

@media only screen and (max-width: 112.5em) {
  /* 1800px */
  .logo {
    width: 35rem;
  }
}

@media only screen and (max-width: 75em) {
  /* 1200px */
  .logo {
    width: 30rem;
  }

  label,
  input,
  input::placeholder,
  button {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 56.25em) {
  /* 900px */
  .logo {
    width: 25rem;
  }
}

@media only screen and (max-width: 37.5em) {
  /* 600px */
  .logo {
    width: 15rem;
  }

  label,
  input,
  input::placeholder,
  button {
    font-size: 0.85rem;
  }
}

@media only screen and (min-width: 112.5em) {
  /* 1800px */
  .logo {
    width: 40rem;
  }
}
</style>
