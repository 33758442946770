<template>
  <div ref="wrapper" class="wrapper">
    <the-constellation></the-constellation>
    <!-- <the-particles></the-particles> -->
    <the-main
      v-on:close-response="close"
      v-on:result-received="resultReceived"
      :isResponseActive="isResponseActive"
      :data="data"
    ></the-main>
    <the-footer></the-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isResponseActive: false,
      data: null,
    };
  },
  // provide() {
  //   return {
  //     isResponseActive: this.isResponseActive,
  //   };
  // },
  methods: {
    resultReceived(data) {
      this.data = data;
      this.isResponseActive = true;
    },
    close() {
      this.data = null;
      this.isResponseActive = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: #123f6b;
  background-image: linear-gradient(
    to right bottom,
    rgba(18, 63, 107, 0),
    #15528e
  );
  height: 100vh;
  min-height: 100vh;
  /* width: 100vw */
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 112.5em) {
  /* 1800px */
}

@media only screen and (max-width: 75em) {
  /* 1200px */
}

@media only screen and (max-width: 71.88em) {
  /* 1150px */
}

@media only screen and (max-width: 56.25em) {
  /* 900px */
}

@media only screen and (max-width: 37.5em) {
  /* 600px */
}

@media only screen and (min-width: 112.5em) {
  /* 1800px */
}
</style>
