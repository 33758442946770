<template>
  <canvas class="constellation"></canvas>
</template>

<script>
export default {

};
</script>

<style scoped>
.constellation {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  -webkit-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>
