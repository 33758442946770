<template>
  <footer class="footer">
    <p class="footer__legal">
      © 2022
      <a href="https://frc.gov.so/" target="_blank" class="footer__legal-link"
        >Somalia FRC. All rights reserved.</a
      >
    </p>
    <div class="footer__social">
      <a
        href="https://www.facebook.com/somaliafrc"
        target="_blank"
        class="footer__social-icon footer__social-facebook"
        ><i class="fa fa-facebook"></i
      ></a>
      <a
        href="https://twitter.com/somaliafrc"
        target="_blank"
        class="footer__social-icon footer__social-twitter"
        ><i class="fa fa-twitter"></i
      ></a>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  background: transparent;
  padding: 0.5rem 2rem;
  flex: 0 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__legal {
  font-size: 1rem;
  font-weight: 300;
  color: #d6d9da;
  margin-bottom: 0;
}

.footer__legal-link {
  position: relative;
  z-index: 120;
  color: inherit;
  transition: all 0.2s;
}

.footer__legal-link:hover {
  text-decoration: underline;
}

.footer__social {
  display: flex;
}

.footer__social-icon {
  position: relative;
  z-index: 120;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1.2rem;
  background-color: rgba(214, 217, 218, 1);
  color: #0d1113;
  border-radius: 50%;
  margin-left: 0.5rem;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__social-facebook {
  color: #3a55b1;
}

.footer__social-twitter {
  color: #00acf0;
}

.footer__social-icon:hover {
  cursor: pointer;
  color: #f5f5f6;
}

.footer__social-facebook:hover {
  background-color: #3a55b1;
}

.footer__social-twitter:hover {
  background-color: #00acf0;
}

@media only screen and (max-width: 112.5em) {
  /* 1800px */
}

@media only screen and (max-width: 75em) {
  /* 1200px */
}

@media only screen and (max-width: 71.88em) {
  /* 1150px */
}

@media only screen and (max-width: 56.25em) {
  /* 900px */

  /* .footer__legal {
    font-size: 1.6rem;
  }

  .footer__social-icon {
    height: 3.6rem;
    width: 3.6rem;
    font-size: 1.6rem;
  } */
}

@media only screen and (max-width: 37.5em) {
  /* 600px */

  .footer {
    flex: 0 0 15%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .footer__legal {
    font-size: 0.8rem;
    text-align: center;
  }

  .footer__social {
    /* width: 15rem; */
    justify-content: space-around;
  }

  .footer__social-icon {
    height: 2rem;
    width: 2rem;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 112.5em) {
  /* 1800px */

  /* .footer__legal {
    font-size: 1.5rem;
  }

  .footer__social-icon {
    margin-left: .6rem;
  } */
}
</style>
