<template>
  <div class="container pt-30">
    <div class="row position-relative">
      <close-button class="btn--close" v-on:click="close"></close-button>
      <div class="col-xl-12 scroll">
        <section class="hk-sec-wrapper">
          <h5 class="hk-sec-title">Details</h5>
          <div class="row">
            <div class="col-sm">
              <div class="table-wrap">
                <div class="table-responsive">
                  <table
                    class="table table-hover table-bordered mb-0 table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Reference No</th>
                        <th>{{ data.pct.reference_no }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Institution Name</th>
                        <td>{{ data.pct.institution_name }}</td>
                      </tr>
                      <tr>
                        <th>Institution Type</th>
                        <td>{{ data.pct.institution_type }}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{{ data.pct.email }}</td>
                      </tr>
                      <tr>
                        <th>Phone No.</th>
                        <td>{{ data.pct.phone_no }}</td>
                      </tr>
                      <tr>
                        <th>Cash Amount</th>
                        <td>{{ data.pct.amount + ' ' + data.pct.currency }}</td>
                      </tr>
                      <tr>
                        <th>Courier Name</th>
                        <td>{{ data.pct.courier_name }}</td>
                      </tr>
                      <tr>
                        <th>Passport No</th>
                        <td>{{ data.pct.passport_no }}</td>
                      </tr>
                      <tr v-if="data.inflow">
                        <th>Source</th>
                        <td>{{ data.inflow ? data.pct.source : '' }}</td>
                      </tr>
                      <tr v-if="data.outflow">
                        <th>destination</th>
                        <td>{{ data.outflow ? data.pct.destination : '' }}</td>
                      </tr>
                      <tr>
                        <th>Flight No</th>
                        <td>{{ data.pct.flight_no }}</td>
                      </tr>
                      <tr>
                        <th>Flight Date</th>
                        <td>{{ data.pct.flight_date }}</td>
                      </tr>
                      <tr>
                        <th>Purpose</th>
                        <td>{{ data.pct.purpose }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>
                          <span class="badge badge-success">{{
                            data.pct.status
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <pdf-container
          v-if="docIsAvailable && docType === 'pdf'"
          :pdfSrc="pdfSrc"
        ></pdf-container>
        <image-container
          v-if="
            docIsAvailable &&
            (docType === 'jpg' || docType === 'jpeg' || docType === 'png')
          "
          :imgSrc="imgSrc"
        ></image-container>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from './CloseButton.vue';
import PdfContainer from './PdfContainer.vue';
import ImageContainer from './ImageContainer.vue';

export default {
  components: { CloseButton, PdfContainer, ImageContainer },
  props: ['data'],
  emits: ['close-response'],
  data() {
    return {
      docIsAvailable: false,
      docType: '',
      pdfSrc: '',
      imgSrc: '',
    };
  },
  methods: {
    close() {
      this.$emit('close-response');
    },
  },
  created() {
    if (this.$props.data.document !== null) {
      const id = this.$props.data.document;
      fetch('https://admin.frc.gov.so/api/v1/documents/' + id + '/info', {
        method: 'GET',
        headers: {
          // 'X-CSRF-TOKEN': '{{ csrf_token() }}',
          // Accept: 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status) {
            this.docIsAvailable = true;
            this.docType = data.document.file_type;
            if (this.docType === 'pdf') {
              this.pdfSrc =
                'https://admin.frc.gov.so/api/v1/documents/' +
                data.document.id +
                '/display';
            } else if (
              this.docType === 'jpg' ||
              this.docType === 'jpeg' ||
              this.docType === 'png'
            ) {
              this.imgSrc =
                'https://admin.frc.gov.so/api/v1/documents/' +
                data.document.id +
                '/display';
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style scoped>
.scroll {
  position: relative;
  z-index: 120;
  max-height: 80vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #1ebccd;
}

::-webkit-scrollbar-thumb:hover {
  background: #16a9b9;
}

.btn--close {
  position: absolute;
  z-index: 130;
  top: 0.5rem;
  right: 2rem;
}
</style>
