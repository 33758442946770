<template>
  <div class="main">
    <query-part
      v-if="!isResponseActive"
      class="query-part"
      @result-received="resultReceived"
    ></query-part>
    <response-part
      v-else
      class="response-part"
      :data="data"
      v-on:close-response="close"
    ></response-part>
  </div>
</template>

<script>
export default {
  props: ['isResponseActive', 'data'],
  emits: ['result-received', 'close-response'],
  methods: {
    close() {
      this.$emit('close-response');
    },
    resultReceived(data) {
      this.$emit('result-received', data);
    },
  },
};
</script>

<style scoped>
.main {
  padding: 1rem 1rem 1rem 1rem;
  flex: 0 0 90%;
  /* position: relative;
  z-index: 120; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 112.5em) {
  /* 1800px */
}

@media only screen and (max-width: 75em) {
  /* 1200px */
}

@media only screen and (max-width: 71.88em) {
  /* 1150px */
}

@media only screen and (max-width: 56.25em) {
  /* 900px */
}

@media only screen and (max-width: 37.5em) {
  /* 600px */

  .main {
    flex: 0 0 85%;
  }
}

@media only screen and (min-width: 112.5em) {
  /* 1800px */
}
</style>
