<template>
  <section class="hk-sec-wrapper">
    <h5 class="hk-sec-title">Courier's Passport</h5>
    <div class="row">
      <div class="col-sm">
        <div id="passport-doc-div" class="bg-light">
          <!-- <img
            style="height: 70vh"
            src="http://frc-verify.test/api/v1/documents/' + pct.passport_document + '/display"
            class="img-fluid mx-auto d-block"
            alt="img"
          /> -->
          <img :src="imgSrc" class="img-fluid mx-auto d-block" alt="img" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['imgSrc'],
};
</script>

<style scoped>
img {
  height: 60vh;
}

@media only screen and (max-width: 112.5em) {
  /* 1800px */
}

@media only screen and (max-width: 75em) {
  /* 1200px */
  img {
    height: 50vh;
  }
}

@media only screen and (max-width: 71.88em) {
  /* 1150px */
  img {
    height: 40vh;
  }
}

@media only screen and (max-width: 56.25em) {
  /* 900px */
  img {
    height: 35vh;
  }
}

@media only screen and (max-width: 37.5em) {
  /* 600px */
}

@media only screen and (min-width: 112.5em) {
  /* 1800px */
}
</style>
