<template>
  <section class="hk-sec-wrapper">
    <h5 class="hk-sec-title">Courier's Passport</h5>
    <div class="row">
      <div class="col-sm">
        <div
          id="passport-doc-div"
          class="embed-responsive embed-responsive-16by9"
        >
          <!-- <iframe
            src="http://frc-verify.test/api/v1/documents/' + pct.passport_document + '/display"
            class="embed-responsive-item"
            allowfullscreen
          ></iframe> -->
          <iframe
            :src="pdfSrc"
            class="embed-responsive-item"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['pdfSrc'],
};
</script>
