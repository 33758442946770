import { createApp } from 'vue';
import App from './App.vue';

import Toaster from '@meforma/vue-toaster';

import TheConstellation from './components/TheConstellation.vue';
import TheParticles from './components/TheParticles.vue';
import TheMain from './components/TheMain.vue';
import QueryPart from './components/QueryPart.vue';
import ResponsePart from './components/ResponsePart.vue';
import TheFooter from './components/TheFooter.vue';

const app = createApp(App);

app.component('the-constellation', TheConstellation);
app.component('the-particles', TheParticles);
app.component('the-main', TheMain);
app.component('query-part', QueryPart);
app.component('response-part', ResponsePart);
app.component('the-footer', TheFooter);

app.use(Toaster);

app.mount('#app');
